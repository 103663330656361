import styled from '@emotion/styled';
import React, { FC } from 'react';
import Avatar, { AvatarProps } from './Avatar';
import TruncateText from './TruncateText';

const AvatarAndNameWrapper = styled.div<{ maxWidth?: string }>(({ maxWidth }) => ({
  maxWidth: maxWidth ? maxWidth : '200px',
  display: 'flex',
}));

const AvatarAndName: FC<AvatarProps & { maxWidth?: string }> = (props) => {
  return (
    <AvatarAndNameWrapper maxWidth={props.maxWidth}>
      <Avatar {...props} />
      <TruncateText>{props.name}</TruncateText>
    </AvatarAndNameWrapper>
  );
};

export default AvatarAndName;
