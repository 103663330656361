// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.20.0
// source: tecton_proto/dataobs/validation.proto

/* eslint-disable */
import { Id } from "../common/id";
import { FeatureExpectation, MetricExpectation } from "./expectation";

export const protobufPackage = "tecton_proto.dataobs";

export enum ExpectationResultEnum {
  /** RESULT_UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  RESULT_UNKNOWN = "RESULT_UNKNOWN",
  RESULT_PASSED = "RESULT_PASSED",
  RESULT_FAILED = "RESULT_FAILED",
  /** RESULT_ERROR - When something wrong with validation. */
  RESULT_ERROR = "RESULT_ERROR",
}

/** Expectation result of a feature view. */
export interface ExpectationResult {
  /** Id of the validation job that this result was produced by. */
  validation_job_id?: Id | undefined;
  workspace?: string | undefined;
  feature_view_name?: string | undefined;
  feature_package_id?: Id | undefined;
  validation_time?: string | undefined;
  feature_interval_start_time?: string | undefined;
  feature_interval_end_time?: string | undefined;
  feature_expectation_metadata?: FeatureExpectationMetadata | undefined;
  metric_expectation_metadata?: MetricExpectationMetadata | undefined;
  result?: ExpectationResultEnum | undefined;
  result_id?: Id | undefined;
}

/** Validation result of a feature expectation */
export interface FeatureExpectationMetadata {
  expectation?:
    | FeatureExpectation
    | undefined;
  /**
   * Alert message corresponding to the expectation. This should explain why
   * expectation failed.
   */
  alert_msg?: string | undefined;
  failure_percentage?: number | undefined;
  failed_join_key_samples?: string[] | undefined;
}

/** Validation result of a metric expectation */
export interface MetricExpectationMetadata {
  expectation?:
    | MetricExpectation
    | undefined;
  /**
   * Alert message corresponding to the expectation. This should explain why
   * expectation failed.
   */
  alert_msg?: string | undefined;
  param_values?: MetricExpectationMetadata_ParamValue[] | undefined;
}

export interface MetricExpectationMetadata_ParamValue {
  metric_name?: string | undefined;
  actual_value?: string | undefined;
  interval_start_time?: string | undefined;
}

export interface ResultSummary {
  passed?: number | undefined;
  failed?: number | undefined;
  error?: number | undefined;
  unknown?: number | undefined;
}

export interface WorkspaceResultSummary {
  workspace?:
    | string
    | undefined;
  /**
   * Counts all the result for all the expectation results in this workspace
   * grouped by result type.
   */
  summary?:
    | ResultSummary
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  feature_view_summary?: FeatureViewResultSummary[] | undefined;
}

export interface FeatureViewResultSummary {
  feature_view_name?:
    | string
    | undefined;
  /**
   * Counts all the result for all the expectation results in this feature view
   * grouped by result type.
   */
  summary?:
    | ResultSummary
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  expectation_summary?: ExpectationResultSummary[] | undefined;
}

export interface ExpectationResultSummary {
  expectation_name?:
    | string
    | undefined;
  /**
   * Counts all the result for all the expectation results of this expectation
   * grouped by result type.
   */
  summary?: ResultSummary | undefined;
}
