// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.20.0
// source: tecton_proto/auth/resource_role_assignments.proto

/* eslint-disable */
import { ResourceType } from "./resource";

export const protobufPackage = "tecton_proto.auth";

export enum RoleAssignmentType {
  ROLE_ASSIGNMENT_TYPE_UNSPECIFIED = "ROLE_ASSIGNMENT_TYPE_UNSPECIFIED",
  ROLE_ASSIGNMENT_TYPE_DIRECT = "ROLE_ASSIGNMENT_TYPE_DIRECT",
  ROLE_ASSIGNMENT_TYPE_FROM_PRINCIPAL_GROUP = "ROLE_ASSIGNMENT_TYPE_FROM_PRINCIPAL_GROUP",
}

export interface ResourceAndRoleAssignments {
  resource_type?:
    | ResourceType
    | undefined;
  /**
   * Can be skipped if the requested resource type was
   * RESOURCE_TYPE_ORGANIZATION.
   */
  resource_id?:
    | string
    | undefined;
  /**
   * TODO: in principal groups implementation, deprecate this field and replace
   * it with a list of AssignmentSummary (a new proto with role + list of where
   * assignment is from) as outlined in Groups RFC
   */
  roles?:
    | string[]
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  roles_granted?: RoleAssignmentSummary[] | undefined;
}

export interface ResourceAndRoleAssignmentsV2 {
  resource_type?:
    | ResourceType
    | undefined;
  /**
   * Can be skipped if the requested resource type was
   * RESOURCE_TYPE_ORGANIZATION.
   */
  resource_id?:
    | string
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  roles_granted?: RoleAssignmentSummary[] | undefined;
}

export interface RoleAssignmentSource {
  assignment_type?:
    | RoleAssignmentType
    | undefined;
  /** empty for direct assignments */
  principal_group_name?: string | undefined;
  principal_group_id?: string | undefined;
}

export interface RoleAssignmentSummary {
  role?: string | undefined;
  role_assignment_sources?: RoleAssignmentSource[] | undefined;
}
