import styled from '@emotion/styled';
import { FC } from 'react';
import LinkIcon from './LinkIcon';

import BookIcon from '@svg/book.svg';
import TruncateText from './TruncateText';

export interface TectonSubtitleProps {
  description?: string;
  learnMoreUrl?: string;
}

const DescriptionBar = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: 0;
`;

const DescriptionStyle = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TectonSubtitle: FC<TectonSubtitleProps> = ({ description, learnMoreUrl }) => {
  return (
    <DescriptionBar data-testid="subtitle">
      <DescriptionStyle>
        <TruncateText>{description}</TruncateText>
      </DescriptionStyle>
      {learnMoreUrl && (
        <>
          <LinkIcon linkName={'Learn More'} href={learnMoreUrl} iconType={BookIcon} target="_blank" />
        </>
      )}
    </DescriptionBar>
  );
};

export default TectonSubtitle;
