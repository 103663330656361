import { EuiCallOut, tint } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';

export type ResultMood = 'success' | 'warning' | 'danger' | 'neutral';

interface TectonCalloutProps {
  children: React.ReactNode;
  title: React.ReactNode;
  mode?: ResultMood;
}

type ColorPair = Record<ResultMood, { titleColor: string; backgroundColor: string }>;

const StyledCallout = styled(EuiCallOut)<{ colorPairs: ColorPair; mode: ResultMood }>`
  background-color: ${({ colorPairs, mode }) => colorPairs[mode].backgroundColor};

  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: ${({ theme }) => theme.padding.l};

  .euiCallOutHeader__title {
    color: ${({ colorPairs, mode }) => colorPairs[mode].titleColor};
    font-size: ${({ theme }) => theme.font.fontSizes.s};
    line-height: ${({ theme }) => theme.font.lineHeights.s};
  }

  .euiText {
    font-size: ${({ theme }) => theme.font.fontSizes.s};
    line-height: ${({ theme }) => theme.font.lineHeights.s};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`;

const TectonCallout: FC<TectonCalloutProps> = ({ title, children, mode = 'neutral' }) => {
  const { theme } = useTectonTheme();

  const colorPairs: ColorPair = {
    neutral: { titleColor: theme.colors.text, backgroundColor: theme.colors.lightestShade },
    danger: { titleColor: theme.colors.danger, backgroundColor: tint(theme.colors.danger, 0.85) },
    success: { titleColor: theme.colors.success, backgroundColor: tint(theme.colors.success, 0.85) },
    warning: { titleColor: theme.colors.warningText, backgroundColor: tint(theme.colors.warning, 0.85) },
  };

  const iconTypeMap: Record<ResultMood, string> = {
    success: 'check',
    warning: 'warning',
    danger: 'cross',
    neutral: 'iInCircle',
  };

  return (
    <StyledCallout title={title} iconType={iconTypeMap[mode]} data-testid="callout" colorPairs={colorPairs} mode={mode}>
      {children}
    </StyledCallout>
  );
};

export default TectonCallout;
