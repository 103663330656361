import styled from '@emotion/styled';
import { Badge } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

interface TabWithCount {
  label: string;
  count: number;
}
interface TectonTabsProps<T = any> {
  selectedTab: string;
  tabs?: string[];
  tabsWithCount?: TabWithCount[];
  setSelectedTab: React.Dispatch<React.SetStateAction<T>>;
}

const TabItemWrapper = styled.div<{ isCurrent: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.padding.m} ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.m}
    ${({ theme }) => theme.padding.xs};
  align-items: center;
  gap: 8px;

  cursor: pointer;
  font-weight: ${({ theme, isCurrent }) => {
    return `${isCurrent ? theme.font.weight.semiBold : theme.font.weight.regular}`;
  }};
  border-bottom: ${({ theme, isCurrent }) => {
    return `${isCurrent ? `1px solid ${theme.colors.fullShade}` : `1px solid rgba(0,0,0,0)`};`;
  }};

  white-space: nowrap;

  :hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightShade};
  }
`;

const Container = styled.div`
  padding: 0px;
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightestShade};
  background-color: ${({ theme }) => theme.colors.emptyShade};
  overflow: auto;
  gap: ${({ theme }) => theme.padding.l};
`;

const TabItem: FC<{
  name: string;
  isCurrent?: boolean;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({ name, isCurrent, setSelectedTab }) => {
  return (
    <TabItemWrapper isCurrent={isCurrent ?? false} onClick={() => setSelectedTab(name)}>
      {name}
    </TabItemWrapper>
  );
};

const TabWithCountItem: FC<{
  tab: TabWithCount;
  isCurrent?: boolean;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({ tab, isCurrent, setSelectedTab }) => {
  return (
    <TabItemWrapper isCurrent={isCurrent ?? false} onClick={() => setSelectedTab(tab.label)}>
      {tab.label}
      <Badge label={tab?.count?.toString()} />
    </TabItemWrapper>
  );
};

const TectonTabs: FC<TectonTabsProps> = ({ selectedTab, tabs, tabsWithCount, setSelectedTab }) => {
  return (
    <Container data-testid="tabs">
      {tabsWithCount ? (
        <>
          {tabsWithCount?.map((tab) => {
            return (
              <TabWithCountItem
                tab={tab}
                isCurrent={selectedTab === tab.label}
                setSelectedTab={setSelectedTab}
                data-testid={`tab-${tab.label}`}
              />
            );
          })}
        </>
      ) : (
        <>
          {tabs?.map((tabName) => {
            return (
              <TabItem
                name={tabName}
                isCurrent={selectedTab === tabName}
                setSelectedTab={setSelectedTab}
                data-testid={`tab-${tabName}`}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};

export default TectonTabs;
