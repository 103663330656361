import styled from '@emotion/styled';
import { NameWrapper, Tooltip } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

interface IconAndTooltip {
  icon: React.ReactNode;
  name: string;
  description?: string;
  isFaded?: boolean;
  highlightColor?: string;
}

const InlineWrapper = styled.div<{ isFaded: boolean; color?: string }>(({ theme, isFaded, color }) => ({
  display: 'inline-flex',
  gap: theme.padding.xs,
  padding: '0px 0px',
  cursor: 'pointer !important',
  alignItems: 'center',
  justifyContent: 'center',
  ...(isFaded
    ? {
        '& path, & ellipse, & rect, & circle': {
          stroke: theme.colors.subduedText,
        },
        color: theme.colors.subduedText,
      }
    : {}),
  ...(!isFaded && color
    ? {
        ':hover': {
          '& path, & ellipse, & rect, & circle': {
            stroke: color,
          },
        },
      }
    : {}),
}));

const IconWrapper = styled.div`
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconAndTooltip: FC<IconAndTooltip> = ({ icon, name, description, isFaded, highlightColor }) => {
  if (isFaded) {
    return (
      <>
        <Tooltip
          title={name}
          content={'This object has been recreated or deleted.'}
          data-testid="tecton-tooltip-content"
          trigger={
            <InlineWrapper isFaded={true} color={highlightColor}>
              <IconWrapper>{icon}</IconWrapper>
              <NameWrapper className="tooltipName" isFaded={isFaded}>
                {name}
              </NameWrapper>
            </InlineWrapper>
          }
        />
      </>
    );
  }

  return (
    <>
      <Tooltip
        title={name}
        content={description || 'No description provided.'}
        data-testid="tecton-tooltip-content"
        trigger={
          <InlineWrapper isFaded={false} color={highlightColor}>
            <IconWrapper>{icon}</IconWrapper>
            <NameWrapper className="tooltipName" isFaded={false}>
              {name}
            </NameWrapper>
          </InlineWrapper>
        }
      />
    </>
  );
};

export default IconAndTooltip;
